.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtms-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtms-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

div.jumbotron {
  box-shadow: 0px 0px 4px #999;
}

:focus-visible {
  outline: 0px;
}

.busy-cursor {
  cursor: wait;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.hover-pointer:hover {
  cursor: pointer;
}

.display-5 {
  @extend .display-4;
  font-size: 2rem;
}

.hover-shade-smoke:hover {
  background-color: rgba($color: #000000, $alpha: 0.02);
}
