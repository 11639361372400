$consilio-orange: #e68606;
$consilio-blue: #002554;
$consilio-text: #53565a;

// $body-bg: ;
$body-color: $consilio-text;
$primary: $consilio-orange;
$light: #f7f7f7;

$enable-rounded: false;
$enable-shadows: true;
$enable-transitions: true;
