clp-layout,
clp-layout-itembar-container,
clp-u-container,
clp-u-container router-outlet + *:last-child,
clp-u-container router-outlet + *:last-child > form {
  overflow: hidden;
}

clp-layout {
  display: flex;
  flex-grow: 1;
  z-index: 1;
}

clp-layout clp-layout-itembar-container {
  display: flex;
  flex-grow: 1;

  > clp-layout-itembar + *:last-child {
    flex-grow: 1;
    background-color: $body-bg;
  }
}

clp-layout clp-layout-itembar {
  z-index: 2;
  @extend .bg-consilio-blue;

  &.shade-2 {
    background-color: rgba($color: $consilio-blue, $alpha: 0.8);

    > ul.list-group {
      li {
        &.active {
          background-color: rgba($color: var(--bs-dark), $alpha: 0.6);
        }
      }
    }
  }

  > ul.list-group {
    > clp-layout-itembar-item-routerlink {
      height: 48px;
      display: flex;

      > li {
        display: flex;
        flex-grow: 1;
      }
    }

    > clp-layout-itembar-item-routerlink > li[disabled="true"] {
      pointer-events: none !important;
    }

    > clp-layout-itembar-item-routerlink li:hover:not(.active) {
      background: rgba($color: #fff, $alpha: 0.1);
      cursor: pointer;
    }

    li {
      border: 0px;
      background-color: inherit;
      align-items: center;

      &.active {
        background-color: rgba($color: #fff, $alpha: 0.3)
      }

      &[disabled="true"] {
        color: gray;

        fa-icon {
          color: gray;
        }
      }

      a {
        color: white;
      }
    }
  }
}

fa-icon + span {
  margin-left: 8px;
}

clp-layout ngb-modal-window {
  z-index: 1060 !important;
}

@import "./layout-topbar.scss";
