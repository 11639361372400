clp-layout-topbar {
  @extend .noselect, .m-0, .p-0;
  box-shadow: 0px 1px 10px #999;
  padding-right: $spacer * 0.25 !important;
  @media (max-width: 992px) {
    padding-right: $spacer * 0.35 !important;
  }

  li div:last-child {
    margin-right: 28px;
  }

  a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    align-items: center;
    display: flex;
    overflow: hidden;
    line-height: 48px;

    img {
      height: 48px;
    }
  }

  #navbar-brand-avatar {
    width: 72px;
  }

  #navbar-brand-text {
    height: 46px !important;
    margin-left: 6px;
    padding-top: 2px;
    line-height: 49px;
  }

  > div:last-child {
    margin-right: 28px;
  }

  .nav-item-user-dropdown.show > a.nav-link {
    text-decoration: underline;
  }

  .nav-item-user-dropdown .dropdown-menu {
    min-width: 180px;
    margin-top: 7px !important;
    margin-right: -5px !important;
    a:active {
      @extend .bg-primary-alt;
    }
  }

  .dropdown-toggle::after {
    content: none;
  }
}
