clp-ulogin,
clp-ulogin-mfapreference {
  @extend .noselect;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;

  .clp-ulogin-box {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 440px;
    width: calc(100% - 40px);
    padding: 44px 44px 33px 44px;
    margin-bottom: 28px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    min-width: 320px;
    min-height: 338px;
    overflow: hidden;
  }

  .clp-ulogin-box-header {
    height: 44px;
    margin-bottom: 44px;
  }

  .label-signin {
    font-weight: 600;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    img {
      height: 32px;
    }

    span {
      margin-left: 6px;
    }
  }

  .clp-ulogin-box-body {
    flex-grow: 1;

    input:focus {
      border-color: $consilio-blue;
      box-shadow: inset 0 1px 1px rgba($consilio-blue, 0.075), 0 0 0 0.2rem rgba($consilio-blue, 0.25);
    }

    .form-buttons {
      margin-top: 44px;

      button {
        min-width: 108px;
      }
    }

    .form-check:not(:first-child) {
      margin-top: 10px;
    }
  }

  .clp-ulogin-box-footer {
    background-color: #f2f2f2;
    padding: 24px 44px 36px 44px;
    margin: 44px -44px -44px -44px;

    @extend .select;
  }
}
