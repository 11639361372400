html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

html,
body,
clp-root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

clp-root,
main {
  display: flex;
  flex-direction: column;
}

#main-container {
  overflow-y: auto;
  flex-grow: 1;
}

main {
  height: 100%;
}

router-outlet + *:last-child {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
