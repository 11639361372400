$globalMinHeight: 768px;
$globalMinWidth: 1024px;

@import "./styles/vars";
@import "./styles/bootstrap.scss";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";


@import "./styles/utils.scss";
@import "./styles/loaders.scss";
@import "./styles/layout.scss";
@import "./styles/modal-core.scss";
@import "./styles/login.scss";
@import "./styles/grid.scss";
@import "./styles/root.scss";
